"use client";

import { Button, buttonVariants } from "@/components/ui/button";
import { Link } from "@/navigation";
import { useTranslations } from "next-intl";
import { useParams } from "next/navigation";
import { type Locale } from "@/navigation";
import { cn } from "@/lib/utils";
import "./notfound.css";
export default function NotFoundclient() {
  const locale = useParams()?.locale as Locale;
  const t = useTranslations("notfound");
  return (
    <div className="container flex flex-col items-start justify-start md:mt-24 md:flex-row md:items-center md:justify-center md:space-x-6 h-[50vh]">
      <div className="space-x-2 pb-8 pt-6 md:space-y-5">
        <h1 className="text-6xl text-[10vw]  font-extrabold leading-9 tracking-tight text-gray-900 dark:text-gray-100 md:border-r-2 md:px-6 md:text-8xl md:leading-14 text-white text-[10vw] font-bold font-helvetica hit-the-floor">
          404
        </h1>
   
      </div>
      <div className="max-w-md">
        <p className="mb-4 text-xl font-bold leading-normal md:text-2xl">
          {t("title")}
        </p>
        <p className="mb-8">{t("description")}</p>
        <Link href="/">
          <Button>{t("back")}</Button>
        </Link>
      </div>
    </div>
  );
}
